import './App.css';
import * as React from 'react'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Typography, Divider, CardContent, Card} from '@mui/material';

function App() {
    const card1 = (
        <React.Fragment>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" paragraph>
                    {'"May your choices reflect your hope."'}
                </Typography>
                <Typography variant="body2" align={"right"}>
                    Nelson Mandela
                </Typography>
            </CardContent>
        </React.Fragment>
    );
    const card2 = (
        <React.Fragment>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" paragraph>
                    {'"We have nothing to fear but fear itself."'}
                </Typography>
                <Typography variant="body2" align={"right"}>
                    Franklin D. Roosevelt
                </Typography>
                <Typography sx={{fontSize: 11}}  align={"right"}>
                    32nd President of the United States
                </Typography>
            </CardContent>
        </React.Fragment>
    );
    return (
        <div className="App">
            <header className="App-header">
                <Typography variant="h1" component="h2">
                    SUPRUN.CA
                </Typography>
                <Divider/>
                <Typography variant="h3" component="h2">
                    <br/> Influence, Inspire, Impact. <br/> Transforming Vision into Reality.
                </Typography>
            </header>
            <header className="App-header">
                <Card variant="outlined" style={{marginRight:300, marginTop:30}}>{card1}</Card>
                <Card variant="outlined" style={{marginRight:-300, marginTop:-30}}>{card2}</Card>
            </header>

        </div>
    );
}

export default App;
