import * as React from 'react';
// import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage =
  'https://www.wdw-magazine.com/wp-content/uploads/2021/05/WDW-Magazine-Magic-Kingdom-Cinderella-Castle-Zoom-Backgrounds-Front-Daytime-Featured.jpg';

const mottos = ["Family First: Together We Inspire, Influence, Impact, and Transform Dreams into Reality.",
    "United in Vision: From Family Bonds to Worldly Impact.",
    "Family Together, Dreams to Reality: We Inspire, Influence, and Impact.",
    "Together as Family, We Turn Visions into Legacy.",
    "Family United: Inspiring Change, Influencing Tomorrow, Impacting Forever.",
    "Bound by Blood, Driven by Vision: Together We Transform.",
    "Family at the Heart, Vision in our Eyes: Inspire, Influence, Impact.",
    "Unity in Family, Power in Vision: We Make Dreams Real.",
    "From Family Roots to Worldly Fruits: We Inspire and Impact.",
    "As One We Stand, With Vision in Hand: Family's Influence Transforms Reality."
];
export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      {/*<img*/}
      {/*  style={{ display: 'none' }}*/}
      {/*  src={backgroundImage}*/}
      {/*  alt="increase priority"*/}
      {/*/>*/}
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Family compass
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
          {mottos[Math.floor((Math.random()*mottos.length))]}
      </Typography>
      {/*<Button*/}
      {/*  color="secondary"*/}
      {/*  variant="contained"*/}
      {/*  size="large"*/}
      {/*  component="a"*/}
      {/*  href="/premium-themes/onepirate/sign-up/"*/}
      {/*  sx={{ minWidth: 200 }}*/}
      {/*>*/}
      {/*  Register*/}
      {/*</Button>*/}
      {/*<Typography variant="body2" color="inherit" sx={{ mt: 2 }}>*/}
      {/*  Discover the experience*/}
      {/*</Typography>*/}
    </ProductHeroLayout>
  );
}
